import * as types from '@/models/api';
import { v4 as uuidv4 } from 'uuid';
import { AdHocTaskResult } from '@/lib/AdHocTasks';
import {
    AddTaskBeforeOrAfterCommand,
    CreateAdHocTaskCommand,
    PlannedLocationViewModel,
    PlannedTaskViewModel,
    TaskTimes,
    TaskTypeViewModel,
    TimeUnit,
    UpdateAdHocTaskCommand, UpdateAdHocWeekTaskCommand,
    UpdateType
} from '@/models/api';
import { TaskType } from '@/models/api/Data/task-type';
import { LockTag } from '@/models/client/client-row';

export const initialShiftLocationDetail = {
    comments: null,
    varianceToPlan: null,
    unavailable: false,
    stockpileId: null,
    materialDestinationId: null,
    materialTypeId: null,
}

export class CommandModelBuilder {
    public UpdateTaskAndEquipmentCommunicationsCommand(data: types.UpdateTaskAndEquipmentCommunicationsCommand): types.UpdateTaskAndEquipmentCommunicationsCommand {
        return { ...data, _type: 'UpdateTaskAndEquipmentCommunicationsCommand' };
    }
    public UpdateWeeklyCommunicationsCommand(data: types.UpdateWeeklyCommunicationsCommand): types.UpdateWeeklyCommunicationsCommand {
        return { ...data, _type: 'UpdateWeeklyCommunicationsCommand' };
    }
    public UpdateShiftCommunicationsCommand(data: types.UpdateShiftCommunicationsCommand): types.UpdateShiftCommunicationsCommand {
        return { ...data, _type: 'UpdateShiftCommunicationsCommand' };
    }
    public UpdateLocationCommentaryCommand(locationId: string, comment: string): types.UpdateLocationCommentaryCommand {
        return {
            _type: 'UpdateLocationCommentaryCommand',
            locationId,
            comment,
            isOfflineReplay: false,
            attemptedAt: new Date(),
        };
    }
    public UpdateShiftLocationDetailsCommand(
        data: types.UpdateShiftLocationDetailsCommand
    ): types.UpdateShiftLocationDetailsCommand {
        return { ...data, _type: 'UpdateShiftLocationDetailsCommand' };
    }
    public UpdateLocationDetailsCommand(
        data: types.UpdateLocationDetailsCommand
    ): types.UpdateLocationDetailsCommand {
        return { ...data, _type: 'UpdateLocationDetailsCommand' };
    }
    public CreateShiftSnapshotCommand(data: types.CreateShiftSnapshotCommand): types.CreateShiftSnapshotCommand {
        return { ...data, _type: 'CreateShiftSnapshotCommand' };
    }
    public SetTaskEquipmentCommand(data: types.SetTaskEquipmentCommand): types.SetTaskEquipmentCommand {
        return { ...data, _type: 'SetTaskEquipmentCommand' };
    }
    public SetCrewForShiftCommand(data: types.SetCrewForShiftCommand): types.SetCrewForShiftCommand {
        return { ...data, _type: 'SetCrewForShiftCommand' };
    }
    public AddRoleToShiftCommand(data: types.AddRoleToShiftCommand): types.AddRoleToShiftCommand {
        return { ...data, _type: 'AddRoleToShiftCommand' };
    }
    public AnswerQuestionCommand(data: types.AnswerQuestionCommand): types.AnswerQuestionCommand {
        return { ...data, _type: 'AnswerQuestionCommand' };
    }
    public UpdateTaskTimesCommand(
        shiftId: string,
        departmentId: string,
        plannedCycleId: string,
        newTimes: TaskTimes[],
        ignoreSpecialShiftTimes: boolean,
        updateType: UpdateType
    ): types.UpdateTaskTimesCommand {
        return {
            _type: 'UpdateTaskTimesCommand',
            shiftId: shiftId,
            departmentId: departmentId,
            plannedCycleId: plannedCycleId,
            times: newTimes,
            ignoreSpecialShiftTimes: ignoreSpecialShiftTimes,
            updateType: updateType,
            isOfflineReplay: false,
            attemptedAt: new Date(),
        };
    }
    public DeleteTaskCommand(
        shiftId: string,
        plannedLocationId: string,
        plannedCycleId: string,
        plannedTaskId: string
    ): types.DeleteTaskCommand {
        return {
            _type: 'DeleteTaskCommand',
            shiftId,
            plannedLocationId,
            plannedCycleId,
            plannedTaskId,
            isOfflineReplay: false,
            attemptedAt: new Date(),
        };
    }
    public AddStaffToEquipmentCommand(data: types.AddStaffToEquipmentCommand): types.AddStaffToEquipmentCommand {
        return { ...data, _type: 'AddStaffToEquipmentCommand' };
    }
    public UpdateShiftVarianceAndCommentaryCommand(
        data: types.UpdateShiftVarianceAndCommentaryCommand
    ): types.UpdateShiftVarianceAndCommentaryCommand {
        return { ...data, _type: 'UpdateShiftVarianceAndCommentaryCommand' };
    }
    public UpdateShiftManningAndEquipmentCommand(
        data: types.UpdateShiftManningAndEquipmentCommand
    ): types.UpdateShiftManningAndEquipmentCommand {
        return { ...data, _type: 'UpdateShiftManningAndEquipmentCommand' };
    }
    public UpdateShiftSafetyAndIncidentsCommand(
        data: types.UpdateShiftSafetyAndIncidentsCommand
    ): types.UpdateShiftSafetyAndIncidentsCommand {
        return { ...data, _type: 'UpdateShiftSafetyAndIncidentsCommand' };
    }
    public RemoveStaffFromEquipmentCommand(
        data: types.RemoveStaffFromEquipmentCommand
    ): types.RemoveStaffFromEquipmentCommand {
        return { ...data, _type: 'RemoveStaffFromEquipmentCommand' };
    }
    public StartStopMeetingCommand(data: types.StartStopMeetingCommand): types.StartStopMeetingCommand {
        return { ...data, _type: 'StartStopMeetingCommand' };
    }
    public SplitTaskCommand(
        shiftId: string,
        delayTaskTypeId: string,
        row: PlannedLocationViewModel,
        task: PlannedTaskViewModel,
        splitDuration: number,
        meta: any
    ): types.SplitTaskCommand {
        const command = {
            shiftId: shiftId,
            cycleType: row.location!.cycleType,
            plannedLocationId: row.id,
            plannedCycleId: task.plannedCycleId,
            plannedTaskId: task.id,
            delayTaskTypeId: delayTaskTypeId,
            delayPlannedTaskId: uuidv4(),
            secondHalfPlannedTaskId: uuidv4(),
            splitDuration: splitDuration,
            _type: 'SplitTaskCommand',
            $meta: meta,
        } as types.SplitTaskCommand;

        return command;
    }
    public CreateAdhocTaskCommand(
        data: AdHocTaskResult,
        departmentWeekId: string,
        taskTypes: TaskTypeViewModel[],
        timeUnits: TimeUnit[],
        tagSet: LockTag
    ): types.CreateAdHocTaskCommand {
        const taskType = taskTypes.find((x) => x.id == data.taskTypeId);
        // @ts-ignore
        const command: CreateAdHocTaskCommand = {
            ...data,
            _type: 'CreateAdHocTaskCommand',
            originallyPlannedOnWeekId: departmentWeekId,
            taskCategoryId: data.taskCategoryId,
            overriddenLocationName: data.overriddenLocationName,
            startTime: new Date(data.startAt),
            isOfflineReplay: false,
            attemptedAt: new Date(),
            $meta: {
                taskType: taskType,
                timeUnits: timeUnits,
            },
            currentPlanningTag: tagSet.current,
            nextPlanningTag: tagSet.next
        };
        return command;
    }

    public UpdateAdhocTaskCommand(
        data: AdHocTaskResult,
        taskTypes: TaskTypeViewModel[],
        tagSet: LockTag
    ): types.UpdateAdHocWeekTaskCommand {
        const taskType = taskTypes.find((x) => x.id == data.taskTypeId);
        // @ts-ignore
        const command: UpdateAdHocWeekTaskCommand = {
            ...data,
            _type: 'UpdateAdHocWeekTaskCommand',
            taskCategoryId: data.taskCategoryId,
            taskId: data.id,
            overriddenLocationName: data.overriddenLocationName,
            currentPlanningTag: tagSet.current,
            nextPlanningTag: tagSet.next
        };
        return command;
    }
}

export class ModelBuilder {
    public Commands = new CommandModelBuilder();
}

export default new ModelBuilder();
